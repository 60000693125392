<template>
  <v-container fluid>
    <CRUD
      id-method="params"
      :form-size="formSize"
      :fields="fields"
      :table="table"
      :api="api"
      @clicked:publish="publish"
    >
    </CRUD>
  </v-container>
</template>

<script>
import CRUD from "@/components/util/CRUD/CRUD";

export default {
  components: {
    CRUD
  },
  data() {
    return {
      formSize: "full", // full or width number, default full
      api: {
        // default: {
        //   url: "/ccms/table/",
        //   params: {
        //     tableName: "cnetd.staffmst"
        //   }
        // },
        list: {
          url: "/ccms/table/list",
          method: "get",
          params: {
            tableName: "cnetd.staffmst"
          }
        },
        get: {
          url: "/ccms/table/get",
          method: "get",
          params: {
            tableName: "cnetd.staffmst"
          }
        },
        lov: {
          url: "/ccms/table/lov",
          method: "get",
          params: {
            tableName: "cnetd.staffmst"
          }
        },
        create: {
          url: "/ccms/table/create",
          method: "post",
          params: {
            tableName: "cnetd.staffmst"
          }
        },
        update: {
          url: "/ccms/table/update",
          method: "put",
          params: {
            tableName: "cnetd.staffmst"
          }
        },
        delete: {
          url: "/ccms/table/delete",
          method: "delete",
          params: {
            tableName: "cnetd.staffmst"
          }
        }
      },
      table: {
        //name: "cnetd.staffmst", //* use to retrieve table using crud api
        text: "Staff", // table name to display in crud component
        key: "StaffID", // primary key of table for various usage
        default: {
          //* used for default table option
          page: 1,
          itemsPerPage: 10,
          sortBy: ["StaffNM"],
          sortDesc: [true]
          //attrs: {'hide-default-footer': true}
        },
        components: {
          //* for hiding component that are not needed
          filter: true, //d[true]
          delete: true, //d[true]
          add: true, //d[true]
          quickFilter: [
            { name: "AreaID", multiple: true, width: "400px" },
            { name: "YesOrNo", multiple: false },
            { name: "StaffNM", type: "text", width: "100px" }
          ]
        },
        customActions: [
          { name: "export", text: "Export" },
          { name: "publish", text: "publish" }
        ]
      },
      fields: [
        {
          text: "Name",
          name: "StaffNM",
          searchable: true,
          input: {
            type: "T",
            control: "textarea",
            disabled: false,
            cols: 12
          }
        },
        {
          text: "Title",
          name: "StaffTitle",
          searchable: true,
          input: {
            validation: ["mandatory"]
          }
        },
        {
          text: "Staff Type",
          name: "StaffTypeID",
          sortable: true, //default all true for ..able
          editable: true,
          listable: true,
          searchable: true, // default false
          foreign: {
            table: "cnetd.stafftypemst",
            key: "StaffTypeID",
            display: "StaffTypeNM"
          },
          input: {
            type: "L",
            control: "",
            validation: ["mandatory"],
            disabled: false,
            multiple: false,
            order: 1
            // lov: [
            //   { text: "A", value: "aa" },
            //   { text: "B", value: "bb" }
            // ]
          }
        },
        {
          text: "Area ID",
          name: "AreaID",
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.areamst",
            key: "AreaID",
            display: "AreaNM",
            where: "areamst.AreaID IN ('AU', 'SG')"
          }
        },
        {
          text: "Create By",
          name: "CreateBy",
          editable: false
        },
        {
          text: "Created Date",
          name: "CreateDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "Updated By",
          name: "UpdateBy",
          editable: false
        },
        {
          text: "Updated Date",
          name: "UpdateDate",
          editable: false
        }
      ]
    };
  },
  created() {},
  methods: {
    publish(e) {
      console.log("TO PUB:", e);
    }
  }
};
</script>
